.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;   
}

.mt-1 {
    margin-top: 0.625rem;
}

.mr-1 {
    margin-right: 0.625rem;
}

.mr-2 {
    margin-right: 1.3rem;
}

.w-100 {
 width: 12.5rem;
}

.w-200 {
width: 12.5rem;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.scrollable {
    position: relative;
    overflow-y: scroll;
    max-height: 40em;
    width: 100%;
    max-width: 94.5rem;
    box-sizing: border-box;
}

.scrollable table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    border-bottom: none;
    z-index: -1;
}

.scrollable table tr.selected {
    background-color: #EDF4FF;
}
.scrollable table tr.selected td.change-color-on-select {
    color: #174A84;
}

.scrollable table td, .scrollable-table table th {
    border-color: #8291B2;
    font-family: Roboto, Helvetica, sans-serif;
    white-space: nowrap;
}

.scrollable thead th {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1em;
    font-size: 0.8em;
    font-weight: 700;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #8291B2;
}

.scrollable table td {
    padding: 10px 1em;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #8291B2;
}

.scrollable thead th:last-child {
    border-right: none;
}

.scrollable table tr td:last-child {
    border-right: none;
}

.scrollable table tr:last-child td:first-child {
    border-bottom-left-radius: 7px;
}

.scrollable table tr:last-child td:last-child {
    border-bottom-right-radius: 7px;
}

.scrollable::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}

.scrollable::-webkit-scrollbar-track {
    background: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #174A84;
    border-radius: 6px;
    border-right: none;
    border-left: none;
}

.scrollable::-webkit-scrollbar-track-piece:end {
    background-color: #D1DBE6;
    border-radius: 6px;
    margin-right: 30px;
}

.scrollable::-webkit-scrollbar-track-piece:start {
    background-color: #D1DBE6;
    border-radius: 6px;
}



