
/* Survey.js */
[class*='FlexContainer__StyledContainer'] {
    caret-color: transparent;
}

/* Survey Table.js */
/*Tables*/


.survey-scrollable-table {
    position: relative;
    overflow-y: scroll;
    width: 100%;
    max-width: 94.5rem;
    box-sizing: border-box;
}

.survey-scrollable-table {
    width: 100%;
    border-spacing: 0;
    overflow-y: auto;
}

.survey-scrollable-table tr.selected {
    background-color: #EDF4FF;
}
.survey-scrollable-table tr.selected td.change-color-on-select {
    color: #174A84;
}

.survey-scrollable-table td, .survey-scrollable-table th {
    border-color: #8291B2;
    font-family: Roboto, Helvetica, sans-serif;
    white-space: nowrap;
}

.survey-scrollable-table thead th {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1em;
    font-size: 0.8em;
    font-weight: 700;
    border: none;
}

thead.first-data tr th {
    background-color: white;
    padding: 15px 1em;
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 300;
    border-top: 1px solid;
    border-right: 1px solid;
    border-bottom: none;
    border-color: #8291B2;
    position: unset !important;
    top: unset !important;
}

thead.first-data tr th:first-of-type {
    border-top-left-radius: 8px;
    border-left: 1px #829182 solid;
}
thead.first-data tr th:last-of-type {
    border-top-right-radius: 8px;
}

.survey-scrollable-table tr:first-of-type {
    border-top: 1px solid #8291B2;
}
.survey-scrollable-table tr:last-of-type {
    border-bottom: none;
}

.survey-scrollable-table tr td:nth-of-type(1) {
    border-left: 1px solid #8291B2;
}

.survey-scrollable-table td {
    padding: 10px 1em;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid #8291B2;
    border-bottom: 1px solid #8291B2;
}

.survey-scrollable-table tr:first-child td {
    border-top: 1px solid #8291B2;
}
.survey-scrollable-table tr:last-child td {
    border-bottom: none;
}

.survey-scrollable-table thead th:last-child {
    border-right: none;
}

.survey-scrollable-table tr td:last-child {
    border-right: none;
}

.survey-scrollable-div {
    overflow-y: auto;
}

.survey-scrollable-div::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}

.survey-scrollable-div::-webkit-scrollbar-track {
    background: transparent;
}

.survey-scrollable-div::-webkit-scrollbar-thumb {
    background-color: #174A84;
    border-radius: 6px;
    border-right: none;
    border-left: none;
}

.survey-scrollable-div::-webkit-scrollbar-track-piece:end {
    background-color: #D1DBE6;
    border-radius: 6px;
    margin-right: 30px;
}

.survey-scrollable-div::-webkit-scrollbar-track-piece:start {
    background-color: #D1DBE6;
    border-radius: 6px;
}

button.survey-list-table-btn {
    min-width: 100px;
    padding: 0.6em 0.7em;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    cursor: pointer;
}


/* Survey Form.js */

input {
    text-align: center;
    font-size: 16px;
    caret-color: auto;
}
  
::-webkit-input-placeholder {
text-align: center;
font-size: 16px;
}

:-moz-placeholder {
    text-align: center;
    font-size: 16px;
}


/* Back and Next Step */
[class*='Utils__CancelButton'], [class*='Utils__NextStepButton'], [class*='Utils__SaveButton'] {
    border-radius: 20px;
    width: 140px;
    height: 2.4rem;
    margin: 5px 5px;
}

[class*='Utils__GridContainer'] {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    text-align: center;
}

.SurveyInsightsGridContainer{
    margin: 4px 0 4px 0;
}


/*Survey Type & User Group Page*/
[class*='Utils__GridSurveyTypeContainer'] {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    text-align: center;
    padding: 5px 15px;
    cursor:pointer;

    border-radius: 26px;
    border: 2px solid #51315D;
    height: 2.4rem;
    margin-bottom: 14px;
    color: #51315D;
}

[class*='Utils__GridUserGroupContainer'] {
    display: grid;
    grid-template-rows: 100%;
    text-align: center;
    padding: 5px 15px;
    cursor:pointer;

    border-radius: 26px;
    border: 2px solid #000000;
    height: 2.4rem;
    margin-bottom: 14px;
    box-shadow: 0 4px 2px -2px gray;
    color: #000000;
}


[class*='Utils__GridSurveyTypeContainer'] > [class*='Utils__GridSurveyTypeChild'], [class*='Utils__GridUserGroupContainer'] > [class*='GridUserGroupChild']{
    display: flex;
    margin: 0 10px 0 10px;
    align-items: center;
}

.surveyTypeDropdown {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #E9EBEF;
    text-decoration: none;
    height: 50px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    color: #000000;
    font-size: 15px;
    font-family: Roboto, Helvetica, sans-serif;
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
}

.surveyTypeSelected, .userGroupSelected {
    color: #FFF;
    background-color: #51315D;
}

[class*='Utils__'] > img {
    margin: 0 2px;
    height: 36px;
}

[class*='Utils__'] > img.icon-surveyInsight {
    height: 46px;
    margin-right: 15px;
}

[class*='Button__StyledButton'] {
    padding: 10px 30px 10px;
    border-radius: 12px;
}

/* Survey Question */
.icon-remove, .add-question{
    cursor:pointer;
}

/* Survey Frequency */
[class*='Utils__InputSelector'] {
    border-radius: 26px;
    border: 1px solid #2D3037;
    height: 2.4rem;
    text-align: center;
    background: url("../../../img/survey/arrow-caret.png") no-repeat right #FFF;
    background-position: calc(100% - 1rem) calc(100% - 0.75rem) !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
}

.tooltip{
    cursor:pointer;
    margin-left:30px;
    top:4px;
    position:relative;
}

/*How Does It Works */
[class*='Utils__'] > img.how-it-works {
    margin: 0px 2px;
    width: 100%;
    height:auto;
}

/* Edit Survey Questions */

.EditQuestionContainer > div {
    width: 100%;
	display:inline-block;
}

.EditQuestionContainer > span {
    position:relative;
    top:35px;
    left: -20px;
    font-weight: 600;
}

/* Survey Insight */

.insight-download-csv{
    padding: 10px 60px 10px;
    background-color: #51315D;
    border-radius: 20px;
    color: #FFF;
    text-decoration:none;
    cursor:pointer;
}

.react-datepicker-wrapper {
    width: 100% !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (max-width: 1200px) {
        margin-bottom: 1rem;
    }
}