
.container {
    width: 425px;
    margin: 0 auto;
}

button.button-credit-card {
    display: block;
    line-height: 1.25em;
    vertical-align: middle;
    height: 45px;
    width: 385px;
    border-radius: 5px;
    text-align: center;
    margin: 5px auto;
    font-size: 22px;
    font-weight: 600;
    background-color: #000;
    color: #fff;
}

button.wallet-button {
    display: block;
    margin: 5px auto;
    border-radius: 10px;
    height: 45px;
    width: 300px;
    overflow: hidden;
    background-clip: border-box;
    background-position: center;
}

#form-container {
    width: 100%;
}

input::placeholder {
    color: #000;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 48px;
}

.modal-body {
    margin: 75px;
}
.display-block {
    display: block;
}

.display-none {
    display: none;
}

/* this is for when additional user cards has been added  */
.card-grid-wrapper {
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 27px;
    grid-row-gap: 26px;
}