
html {
    touch-action: manipulation;
}

.dialog-container {
    position: fixed;
    right: 1em;
    bottom: 1em;
    min-width: 20em;
    height: fit-content;
    z-index: 9999;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

body {
    background-color: #EAEDF4;
}

.login-radios {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #000;
    -webkit-appearance: none;
    cursor: pointer;
}

.login-radios:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    
    border-radius: 50%;
}

.login-radios:checked:before {
    background: black;
}

.login-radios:checked {
    border-color:black;
    padding: 5px;
}

.inactive-chat {
    border: 1px solid transparent;
    border-radius: 0.625rem;
}

.active-chat {
    background-color: #F9FAFC;
    border: 1px solid #DBE5ED;
    border-radius: 0.625rem;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-centered-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled-element {
    opacity: 0.5;
    pointer-events: none;
}

.scroll-design {
    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        margin-top: 2.5rem;
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #FFFFFF;
        border-radius: 0.375rem;
    }
}