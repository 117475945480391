/*Tables*/


.tableContainerOverFlow{
    overflow: scroll;
    max-height: 50vh;
}

table.tableWithStickyHeader{
   position: relative;
   border-collapse: collapse;
}

tr.sticky{
    position: sticky;
    top: 0;
}

[class*='ClientLicenseTable__'] > th{
    background-color: #000000;
    color: white;
    padding: 20px 10px;
    font-size: 13px;
    font-weight: 100;
}

[class*='ClientLicenseTable__'] > th, [class*='ClientLicenseTable__'] > td{
    border-right:1px solid #2D3037;
}

[class*='ClientLicenseTable__'] > td {
    text-align: center;
    padding: 10px;
    font-size: 13px;
}

.tableContainerOverFlow::-webkit-scrollbar{
    width: .4em;
    height: .4em;
}

.tableContainerOverFlow::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.tableContainerOverFlow::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 48px;
}

[class*='ClientLicenseTable__Table'] [class*='Button__StyledButton'] {
    padding: 10px 30px 10px;
    border-radius: 20px;
}
/*Add Client License Form */

/*Edit Client License Form */

/*Send Invite */

/*Purchase License */
[class*='Utils__GridContainer'] {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
    text-align: center;
}

/*Payment Confirmation */

/* License Successful */
